#modal-wrapper {
    background-color: var(--alternate-translucent);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 100000;
    display: flex;
    justify-content: center; 
    align-items: center;
}

#modal {
    background-color:var(--main-background);
    border: 2px solid var(--main-font);
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 90vh;
    margin: auto;
    overflow-y: auto;
    padding: 30px;
   
    width: 100%;
    max-width: 800px;
   
}

#modal.full-screen {
    /** This is a hard-coded value based on the current map size.
    A more automatic-updating system would be nice. */
    max-width: 1250px;
}

#modal h1 {
    margin-top: 0;
}

td.time {
    white-space: nowrap;
}

th.break {
    padding: 0%;
}

#modal #close-button {
    float: right;
    position: relative;
    padding: 0px;
    background: transparent;
}

.riddle {
    white-space: pre;
    padding: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--main-font);
}

#bonus-modal .with-line-breaks {
    white-space: pre;
}

@media (max-width: 600px) {
    #modal {
        padding: 1rem;
    }
}