#chat-input {
  margin-right: 1em;
  width: 80%;
}

#input {
  bottom: 60px;
  margin-top: 2em;
  width: 100%;
}

#input .mentions {
  margin-right: 1rem;
}

.main-input {
  width: 100%;
  border-radius: 5px;
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 14px;
  height: 2rem;
  background-color: white;
  white-space: nowrap;
}

.mentions {
  color:black;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 0px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #87ceeb;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}