@font-face {
  font-family: IBM Plex Mono;
  src: url("IBMPlexMono-Medium.6b5e0097.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex Mono;
  src: url("IBMPlexMono-MediumItalic.bcd8a940.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: IBM Plex Mono;
  src: url("IBMPlexMono-SemiBold.9ebb59fb.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: IBM Plex Mono;
  src: url("IBMPlexMono-SemiBoldItalic.15002352.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Oswald;
  src: url("Oswald-VariableFont_wght.2c7eac91.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: TerminalFont;
  src: url("Web437_IBM_VGA_8x16.db33b8bc.woff") format("truetype");
  font-weight: bold;
  font-style: normal;
}

#modal-wrapper {
  background-color: var(--alternate-translucent);
  z-index: 100000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

#modal {
  background-color: var(--main-background);
  border: 2px solid var(--main-font);
  box-sizing: border-box;
  max-height: 90vh;
  width: 100%;
  max-width: 800px;
  border-radius: 4px;
  margin: auto;
  padding: 30px;
  overflow-y: auto;
}

#modal.full-screen {
  max-width: 1250px;
}

#modal h1 {
  margin-top: 0;
}

td.time {
  white-space: nowrap;
}

th.break {
  padding: 0%;
}

#modal #close-button {
  float: right;
  background: none;
  padding: 0;
  position: relative;
}

.riddle {
  white-space: pre;
  border-style: solid;
  border-width: 2px;
  border-color: var(--main-font);
  padding: 10px;
}

#bonus-modal .with-line-breaks {
  white-space: pre;
}

@media (max-width: 600px) {
  #modal {
    padding: 1rem;
  }
}

:root {
  --main-background: #212121;
  --main-font: #eee;
  --gray-text: #b5b5b5;
  --alternate-background: #333;
  --alternate-translucent: #333c;
  --green: #20b2aa;
  --highlight-line: #666;
  --mod-line: #2f2f2f;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #0ff;
  --magenta: #f0f;
  --orange: orange;
  --red: red;
  --violet: violet;
  --yellow: #ff0;
  --active: #20b2aa;
  --link: #20b2aa;
}

.solarized-dark {
  --main-background: #002b36;
  --main-font: #657b83;
  --gray-text: #586e75;
  --alternate-background: #073642;
  --alternate-translucent: #073642cc;
  --green: #859900;
  --highlight-line: #fdf6e3;
  --mod-line: #073642;
  --pink: #d33682;
  --blue: #268bd2;
  --cyan: #2aa198;
  --magenta: #d33682;
  --orange: #cb4b16;
  --red: #dc322f;
  --violet: #6c71c4;
  --yellow: #b58900;
  --active: #859900;
  --link: #859900;
}

.solarized-light {
  --main-background: #fdf6e3;
  --main-font: #657b83;
  --gray-text: #93a1a1;
  --alternate-background: #eee8d5;
  --alternate-translucent: #eee8d5cc;
  --green: #859900;
  --highlight-line: #002b36;
  --mod-line: #eee8d5;
  --pink: #d33682;
  --blue: #268bd2;
  --cyan: #2aa198;
  --magenta: #d33682;
  --orange: #cb4b16;
  --red: #dc322f;
  --violet: #6c71c4;
  --yellow: #b58900;
  --active: #859900;
  --link: #859900;
}

.monochrome-amber {
  --main-background: #212121;
  --main-font: #fc0;
  --gray-text: #ffb000;
  --alternate-background: #212121;
  --alternate-translucent: #0a0a0acc;
  --green: #20b2aa;
  --highlight-line: #ffb000;
  --highlighted-line-font: #212121;
  --mod-line: #212121;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #0ff;
  --magenta: #f0f;
  --orange: orange;
  --red: red;
  --violet: violet;
  --yellow: #ff0;
  --link-decoration: underline;
  --link-style: dotted;
  --active: #ffa300;
  --link: #ffa300;
}

.monochrome-green {
  --main-background: #212121;
  --main-font: #3f3;
  --gray-text: #0f6;
  --alternate-background: #212121;
  --alternate-translucent: #0a0a0acc;
  --green: #20b2aa;
  --highlight-line: #3f3;
  --highlighted-line-font: #212121;
  --mod-line: #212121;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #0ff;
  --magenta: #f0f;
  --orange: orange;
  --red: red;
  --violet: violet;
  --yellow: #ff0;
  --link-decoration: underline;
  --link-style: dotted;
  --active: #6f6;
  --link: #6f6;
}

.vaporwave {
  --main-background: #302c7b;
  --main-font: #b1ece5;
  --gray-text: #3c88cc;
  --alternate-background: #1e1c4f;
  --alternate-translucent: #0a0a0acc;
  --green: #20b2aa;
  --highlight-line: #f440ac;
  --highlighted-line-font: #231b54;
  --mod-line: #3c88cc;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #0ff;
  --magenta: #f0f;
  --orange: orange;
  --red: red;
  --violet: violet;
  --yellow: #ff0;
  --link-decoration: underline;
  --link-style: wavy;
  --active: #1e1c4f;
  --link: violet;
}

.hotdogstand {
  --main-background: red;
  --main-font: #000;
  --gray-text: #888;
  --alternate-background: #ff0;
  --alternate-translucent: #ff0c;
  --green: #0f0;
  --highlight-line: #fff;
  --highlighted-line-font: red;
  --pink: hotpink;
  --blue: #00f;
  --cyan: #0ff;
  --magenta: #f0f;
  --orange: orange;
  --red: red;
  --violet: violet;
  --yellow: #ff0;
  --link-decoration: underline;
  --link-style: dotted;
  --active: #ff0;
  --link: #fff;
}

.summer-camp {
  --main-background: #f5efe6;
  --main-font: #213220;
  --gray-text: #474f47;
  --alternate-background: #a2c579;
  --alternate-translucent: #027975cc;
  --green: #037705;
  --highlight-line: #027975;
  --highlighted-line-font: #f5efe6;
  --pink: #a13660;
  --blue: #10106d;
  --cyan: #027975;
  --magenta: #8f008f;
  --orange: #c75117;
  --red: #a00;
  --violet: #584b58;
  --yellow: #968c03;
  --link-decoration: underline;
  --link-style: dotted;
  --active: #027975;
  --link: #87351d;
}

.summer-camp-dark {
  --main-background: #213220;
  --main-font: #f5efe6;
  --gray-text: #b7beb7;
  --alternate-background: #40603e;
  --alternate-translucent: #a2c579cc;
  --green: #10cb14;
  --highlight-line: #f5efe6;
  --highlighted-line-font: #87351d;
  --pink: #ff8ac4;
  --blue: #47a3ff;
  --cyan: #00dbdb;
  --magenta: #ff5cff;
  --orange: #ff8040;
  --red: #f66;
  --violet: #cf0dcf;
  --yellow: #d2c50d;
  --link-decoration: underline;
  --link-style: dotted;
  --active: #027975;
  --link: #02d4cd;
}

body {
  background-color: var(--main-background);
  color: var(--main-font);
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
}

#app {
  grid-gap: 3%;
  grid-template-columns: 250px 5fr 1fr;
  padding: 0 3%;
  display: grid;
}

@media only screen and (max-device-width: 500px) {
  #app {
    box-sizing: border-box;
    padding: 5px;
  }
}

#app-profile-open {
  grid-gap: 3%;
  grid-template-columns: 250px 5fr 400px;
  padding: 0 3%;
  display: grid;
}

@media only screen and (max-device-width: 500px) {
  #app {
    grid-template-columns: auto;
  }
}

#main {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

a {
  color: var(--link);
  -webkit-text-decoration: var(--link-decoration, none);
  text-decoration: var(--link-decoration, none);
  -webkit-text-decoration-style: var(--link-style, none);
  text-decoration-style: var(--link-style, none);
  font-weight: bold;
}

.whisper {
  color: var(--gray-text);
  cursor: pointer;
  font-style: italic;
}

.error {
  color: var(--pink);
  font-style: italic;
}

a:visited {
  color: var(--link);
}

.link-styled-button {
  color: var(--link);
  cursor: pointer;
  text-align: left;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
  font-size: 1em;
  display: inline;
}

button {
  background-color: var(--main-background);
  border: 2px solid var(--highlight-line);
  color: var(--main-font);
  cursor: pointer;
  border-radius: 5px;
  padding: .5rem 1rem;
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
  font-size: 16px;
}

input[type="radio"] {
  vertical-align: baseline;
  max-width: 20px;
  margin-right: 1em;
}

#settingsContainer div {
  display: block;
}

.settingsContainer .radio {
  margin-left: 1em;
}

#movementNotificationForm input[type="checkbox"] {
  max-width: 20px;
  margin: 1em;
}

#movementNotificationForm input[type="number"] {
  max-width: 40px;
  margin: 1em;
}

td {
  padding-right: 1em;
}

#code-of-conduct li {
  margin-bottom: 1.5em;
  padding-left: .5em;
}

#code-of-conduct ul {
  padding-left: 2em;
  list-style-type: circle;
}

.form-header {
  margin-bottom: .5em;
  font-size: 1.3em;
  display: block;
}

.settingsContainer .form {
  margin-bottom: 1em;
}

.spin {
  animation: rotate .5s linear 2;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

#badges .badge {
  font-size: 25px;
}

#badges .selected-badge {
  border: 2px solid var(--main-font);
  min-height: 32px;
  min-width: 1em;
  text-align: center;
  vertical-align: middle;
  background-color: #fff3;
  border-radius: 2px;
  margin: 5px;
  padding: 0 3px;
  font-size: 25px;
  line-height: 32px;
  display: inline-block;
}

#badges .unlocked-badge, #badges .locked-badge {
  height: 28px;
  width: 30px;
  padding: 8px 4px 4px;
}

#badges .locked-badge {
  opacity: .5;
  cursor: default;
}

#badges .unlocked-badge.selected {
  width: 26px;
}

#badges .selected {
  background: var(--alternate-background);
  border: 2px solid var(--active);
  border-radius: 2px;
}

#badges .badge-sections {
  width: 100%;
  display: inline-flex;
}

#badges .badge-sections .equipped {
  width: 33%;
  flex-shrink: 1;
  padding-right: 4rem;
}

#badges .toggle-badge-category {
  color: var(--link);
  cursor: pointer;
  border: 0;
}

#chat-input {
  width: 80%;
  margin-right: 1em;
}

#input {
  width: 100%;
  margin-top: 2em;
  bottom: 60px;
}

#input .mentions {
  margin-right: 1rem;
}

.main-input {
  width: 100%;
  height: 2rem;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 5px;
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
  font-size: 14px;
}

.mentions {
  color: #000;
}

.mentions--singleLine .mentions__control {
  width: 0;
  display: inline-block;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
}

.mentions--singleLine .mentions__input {
  border: 2px inset;
  padding: 1px;
}

.mentions__suggestions__list {
  background-color: #fff;
  border: 1px solid #00000026;
}

.mentions__suggestions__item {
  border-bottom: 1px solid #00000026;
  padding: 5px 15px;
}

.mentions__suggestions__item--focused {
  background-color: #87ceeb;
}

.mentions__mention {
  z-index: 1;
  color: #00f;
  text-shadow: 1px 1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff, -1px -1px 1px #fff;
  pointer-events: none;
  text-decoration: underline;
  position: relative;
}

video {
  box-sizing: border-box;
  width: 180px;
  height: 135px;
  display: block;
}

input#send-audio {
  margin-left: 1em;
}

#media-view {
  height: 200px;
  flex-flow: wrap;
  margin-left: 1rem;
  display: flex;
  overflow: hidden;
}

.participant-track-square {
  max-height: 195px;
  min-height: 195px;
  min-width: 180px;
  max-width: 180px;
  overflow: hidden;
}

video.speaking {
  border: 5px solid var(--active);
}

#media-view .video-button {
  color: var(--main-font);
  padding: 10px;
}

.other-participant {
  margin-left: 2px;
  margin-right: 2px;
}

#media-view .other-participant .video-button {
  color: var(--main-font);
  float: right;
  padding: 10px;
}

#media-view .video-button.enabled {
  color: var(--active);
}

#media-view .other-participant .video-button.enabled {
  color: var(--main-font);
}

#media-view .video-button.disabled {
  color: var(--pink);
}

.my-video video {
  transform: scale(-1, 1);
}

#media-selector .videoAudioSettingsContainer {
  margin-top: 2em;
}

#media-selector .my-video, #media-selector .selects {
  float: left;
}

#media-selector .selects {
  margin-top: 1em;
  margin-left: 30px;
}

#media-selector select {
  display: block;
}

#media-selector #join {
  background-color: var(--main-background);
  border: 2px solid var(--highlight-line);
  color: var(--main-font);
  cursor: pointer;
  border-radius: 5px;
  margin-top: 2rem;
  padding: .5rem 1rem;
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
  font-size: 16px;
  display: block;
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.offscreen-count {
  margin-top: .5em;
  margin-bottom: .5em;
}

#join-audio-chat {
  margin-left: 2em;
}

#media-wrapper {
  margin-bottom: 1em;
}

#media-chat-buttons {
  margin-top: 1em;
  margin-bottom: 1em;
}

.participant-track-square, .my-video {
  position: relative;
}

.placeholder-avatar {
  position: absolute;
  top: 55px;
  left: 45px;
}

#play-audio, #play-video, #send-video, #send-audio, #show-media-selector {
  position: absolute;
  bottom: 0;
}

#play-audio {
  right: 0;
}

#play-video {
  right: 30px;
}

#send-audio {
  left: 30px;
}

#send-video {
  left: 0;
}

#show-media-selector {
  left: 60px;
}

nav.react-contextmenu {
  background-color: var(--alternate-background);
  border: 1px solid var(--main-font);
  cursor: pointer;
  z-index: 1;
}

@media only screen and (max-device-width: 500px) {
  nav.react-contextmenu {
    font-size: 40px;
  }
}

.react-contextmenu-item {
  padding: 2px 5px;
}

.react-contextmenu-item--selected {
  color: var(--highlighted-line-font, --main-font);
  background-color: var(--highlight-line) !important;
}

span.name {
  cursor: pointer;
}

.name .react-contextmenu {
  color: var(--main-font);
  font-style: normal;
}

.name.Blue {
  color: var(--blue);
}

.name.Cyan {
  color: var(--cyan);
}

.name.Green {
  color: var(--green);
}

.name.Magenta {
  color: var(--magenta);
}

.name.Orange {
  color: var(--orange);
}

.name.Red {
  color: var(--red);
}

.name.Violet {
  color: var(--violet);
}

.name.Yellow {
  color: var(--yellow);
}

.name.font-Comic {
  font-family: Comic Sans MS, Comic Sans, cursive;
}

.name.font-Classic {
  font-family: TerminalFont;
}

.name.font-Impactful {
  font-family: Oswald;
}

.name .badge {
  margin-left: 3px;
  margin-right: 1px;
}

.custom-badge {
  max-height: 20px;
  margin-bottom: -4px;
}

.message-wrapper .time {
  color: var(--gray-text);
  float: left;
  text-transform: lowercase;
  margin-right: 1.5em;
  font-size: .8em;
}

.message-wrapper {
  padding: .25rem;
}

.message-wrapper .time.show-on-hover {
  opacity: 0;
}

.message-wrapper:hover .time.show-on-hover {
  opacity: 1;
}

.message-wrapper.even-message {
  background-color: var(--alternate-background);
}

.message {
  overflow-wrap: anywhere;
}

.movement-message {
  opacity: .5;
}

.inline-custom-emoji {
  height: 1.3rem;
  position: relative;
  bottom: -.2rem;
}

.message-item {
  width: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
}

.message-list {
  border: 1px solid var(--fbc-white);
  height: 40vh;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.messages-load-progress {
  background: var(--main-background);
  width: 100%;
  height: 40vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.sentinel {
  width: 100%;
  height: 1px;
  position: absolute;
}

.note-wall-description {
  margin-bottom: 2em;
}

.note {
  border: 1px dashed var(--highlight-line);
  width: 40%;
  vertical-align: top;
  word-wrap: break-word;
  border-radius: .25rem;
  margin: 1.25rem;
  padding: .5rem;
  display: inline-block;
}

button.note-delete {
  color: var(--pink);
  float: right;
  margin-left: 10px;
}

.like-button {
  color: var(--blue);
  float: right;
  padding-left: 20px;
  padding-right: 15px;
}

.like-button svg {
  margin-left: 4px;
  vertical-align: top !important;
}

@media (max-width: 600px) {
  .note {
    width: 90%;
  }
}

input[type="text"] {
  height: 2rem;
  border-radius: 5px;
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
  font-size: 14px;
}

.close {
  background-color: var(--main-background);
  color: var(--main-font);
  cursor: pointer;
  border: none;
  place-self: start end;
  font-size: 24px;
}

.container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#profile-edit .field {
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
  display: flex;
}

#profile-edit .form {
  width: 100%;
  width: 780px;
}

.ftue .form {
  margin-top: 8rem;
}

#profile-edit .form label {
  width: 600px;
  font-size: 1.6em;
}

.grid {
  grid-column-gap: 5%;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  display: grid;
}

#profile-edit .submit {
  float: right;
}

#profile-edit h1 {
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  #profile-edit .field {
    margin-bottom: 2rem;
  }

  .ftue .form {
    margin-top: 1rem;
  }

  #profile-edit em {
    line-height: 2em;
  }
}

#profile .fixed {
  height: 100%;
  position: fixed;
  overflow-y: auto;
}

@media only screen and (max-device-width: 500px) {
  #profile {
    background-color: var(--main-background);
    height: 100vh;
    width: 100vw;
    font-size: 40px;
    position: fixed;
    top: 0;
    left: 0;
  }

  #profile input {
    width: 30px;
  }
}

#profile .close-profile {
  height: 2rem;
  padding-top: 0;
  padding-bottom: 0;
}

#profile h1 {
  margin-top: 0;
}

#profile {
  background-color: var(--main-background);
  border-left: 1px solid var(--main-font);
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0 0 20px 20px;
}

#header {
  word-break: break-all;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#profile-close {
  background-color: var(--main-background);
  color: var(--main-font);
  cursor: pointer;
  width: 30px;
  border: none;
  font-size: 24px;
}

#chat-container {
  border-top: 1px dashed var(--main-font);
  margin-top: 20px;
}

#chat-container #send {
  flex-shrink: 0;
}

#chat-header {
  background-color: var(--main-background);
  width: 100%;
  padding: 20px 0;
  top: 0;
}

#chat {
  height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
}

#input {
  align-items: center;
  display: flex;
}

input {
  box-sizing: border-box;
  width: 100%;
}

#side-menu {
  width: 250px;
  z-index: 1;
  margin-top: 30px;
  list-style: none;
  position: fixed;
}

@media only screen and (max-device-width: 500px) {
  #side-menu {
    background-color: var(--main-background);
    height: 100vh;
    width: 100vw;
    z-index: 10;
    margin-top: 0;
    font-size: 30px;
    position: fixed;
    top: 0;
    left: 0;
    overflow: scroll;
  }

  #side-menu #close-button {
    margin-right: 20px;
    font-size: 64px;
  }
}

#side-menu li {
  cursor: pointer;
  list-style: none;
}

button.nav-item {
  color: var(--main-font);
  cursor: pointer;
  text-align: left;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Mono, Consolas, Courier New, Courier, monospace;
  font-size: 1em;
  display: inline;
}

button.nav-item:hover, button.nav-item:focus, button.nav-item:active {
  text-decoration: none;
}

#menu-button {
  cursor: pointer;
  -webkit-user-select: none;
}

#side-menu ul {
  padding: 15px;
}

#nav-map-button {
  margin-top: 2em;
  margin-left: 15px;
}

#mini-map {
  border: 1px solid var(--main-font);
  width: 100%;
  height: 300px;
  overflow: hidden;
}

#mini-map .map, #mini-map .map pre {
  width: 100%;
  height: 100%;
}

#mini-map .map {
  font-size: 10px;
}

/*# sourceMappingURL=admin.c0187fbe.css.map */
