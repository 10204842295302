@import "./fonts.css";
@import "./modal.css";

/* Default theme */
:root {
  --main-background: #212121;
  --main-font: #eee;
  --gray-text: #b5b5b5;
  --alternate-background: #333333;
  --alternate-translucent: rgba(51,51,51,0.8);
  --green: #20b2aa;
  --highlight-line: #666666;
  --mod-line: #2f2f2f;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #00ffff;
  --magenta: #ff00ff;
  --orange: #ffa500;
  --red: #ff0000;
  --violet: #ee82ee;
  --yellow: #ffff00;
  --active: #20b2aa;
  --link: #20b2aa;
}

/* solarized dark */
.solarized-dark{
  --main-background:#002b36;
  --main-font:#657b83;
  --gray-text:#586e75;
  --alternate-background: #073642;
  --alternate-translucent: rgba(7,54,66,0.8);
  --green: #859900;
  --highlight-line: #fdf6e3;
  --mod-line: #073642;
  --pink: #d33682;
  --blue: #268bd2;
  --cyan: #2aa198;
  --magenta: #d33682;
  --orange: #cb4b16;
  --red: #dc322f;
  --violet: #6c71c4;
  --yellow: #b58900;
  --active: #859900;
  --link: #859900;
}

/* solarized light */
.solarized-light{
  --main-background:#fdf6e3;
  --main-font:#657b83;
  --gray-text:#93a1a1;
  --alternate-background: #eee8d5;
  --alternate-translucent: rgba(238,232,213,0.8);
  --green:#859900;
  --highlight-line: #002b36;
  --mod-line: #eee8d5;
  --pink: #d33682;
  --blue: #268bd2;
  --cyan: #2aa198;
  --magenta: #d33682;
  --orange: #cb4b16;
  --red: #dc322f;
  --violet: #6c71c4;
  --yellow: #b58900;
  --active:#859900;
  --link:#859900;
}

/* monochrome-amber */
.monochrome-amber {
  --main-background:#212121;
  --main-font:#FFCC00;
  --gray-text:#FFB000;
  --alternate-background: #212121;
  --alternate-translucent: rgba(10,10,10,0.8);
  --green:#20b2aa;
  --highlight-line: #FFB000 ;
  --highlighted-line-font: #212121;
  --mod-line: #212121 ;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #00ffff;
  --magenta: #ff00ff;
  --orange: #ffa500;
  --red: #ff0000;
  --violet: #ee82ee;
  --yellow: #ffff00;
  --link-decoration: underline;
  --link-style: dotted; 
  --active: #FFA300;
  --link: #FFA300;
  }

/* monochrome-green */
.monochrome-green {
  --main-background: #212121;
  --main-font:#33FF33;
  --gray-text:#00FF66;
  --alternate-background: #212121;
  --alternate-translucent: rgba(10,10,10,0.8);
  --green:#20b2aa;
  --highlight-line: #33FF33;
  --highlighted-line-font: #212121;
  --mod-line: #212121 ;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #00ffff;
  --magenta: #ff00ff;
  --orange: #ffa500;
  --red: #ff0000;
  --violet: #ee82ee;
  --yellow: #ffff00;
  --link-decoration: underline;
  --link-style: dotted; 
  --active: #66FF66;
  --link: #66FF66;
  }

  .vaporwave {
  --main-background: #302c7b;
  --main-font:#B1ECE5; ;
  --gray-text:#3c88cc;
  --alternate-background: #1e1c4f;
  --alternate-translucent: rgba(10,10,10,0.8);
  --green:#20b2aa;
  --highlight-line: #f440ac;
  --highlighted-line-font: #231b54;
  --mod-line: #3c88cc ;
  --pink: lightpink;
  --blue: #87ceeb;
  --cyan: #00ffff;
  --magenta: #ff00ff;
  --orange: #ffa500;
  --red: #ff0000;
  --violet: #ee82ee;
  --yellow: #ffff00;
  --link-decoration: underline;
  --link-style: wavy; 
  --active: #1e1c4f;
  --link: #ee82ee; 
  }
  
.hotdogstand {
  --main-background: #ff0000 ;
  --main-font:#000000;
  --gray-text:#888888;
  --alternate-background: #ffff00;
  --alternate-translucent: rgba(255, 255, 0, 0.8);
  --green:#00ff00;
  --highlight-line: #ffffff;
  --highlighted-line-font: #ff0000;
  --pink: hotpink;
  --blue: #0000ff;
  --cyan: #00ffff;
  --magenta: #ff00ff;
  --orange: #ffa500;
  --red: #ff0000;
  --violet: #ee82ee;
  --yellow: #ffff00;
  --link-decoration: underline;
  --link-style: dotted; 
  --active: #ffff00;
  --link: #ffffff; 
}  


  
.summer-camp {
  --main-background: #F5EFE6;
  --main-font:#213220;
  --gray-text:#474f47;
  --alternate-background: rgb(162, 197, 121);
  --alternate-translucent: rgba(2, 121, 117, 0.8);
  --green:#037705;
  --highlight-line: rgb(2, 121, 117);
  --highlighted-line-font: #F5EFE6;
  --pink: #A13660;
  --blue: #10106d;
  --cyan: rgb(2, 121, 117);
  --magenta: #8F008F;
  --orange: #c75117;
  --red: #AA0000;
  --violet: #584B58;
  --yellow: #968C03;
  --link-decoration: underline;
  --link-style: dotted; 
  --active: #027975;
  --link: #87351D;
}  

.summer-camp-dark {
  --main-background: #213220;
  --main-font:#F5EFE6;
  --gray-text:#B7BEB7;
  --alternate-background: #40603E;
  --alternate-translucent: rgba(162, 197, 121, 0.8);
  --green:#10CB14;
  --highlight-line: #F5EFE6;
  --highlighted-line-font: #87351D;
  --pink: #FF8AC4;
  --blue: #47A3FF;
  --cyan: #00DBDB;
  --magenta: #FF5CFF;
  --orange: #FF8040;
  --red: #FF6666;
  --violet: #cf0dcf;
  --yellow: #d2c50d;
  --link-decoration: underline;
  --link-style: dotted; 
  --active: #027975;
  --link: #02D4CD;
}
  
body {
  background-color: var(--main-background);
  color: var(--main-font);
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
}

#app {
  padding: 0 3%;
  display: grid;
  grid-template-columns: 250px 5fr 1fr;
  grid-gap: 3%;
}

@media only screen and (max-device-width: 500px) {
  #app {
    padding: 5px;
    box-sizing: border-box;
  }
}

#app-profile-open {
  padding: 0 3%;
  display: grid;
  grid-template-columns: 250px 5fr 400px;
  grid-gap: 3%;
}

@media only screen and (max-device-width: 500px) {
  #app {
    grid-template-columns: auto;
  }
}

#main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

a {
  color:var(--link);
  text-decoration: var(--link-decoration, none);
  text-decoration-style: var(--link-style, none);
  font-weight: bold;
}

.whisper {
  color: var(--gray-text);
  cursor: pointer;
  font-style: italic;
}

.error {
  color: var(--pink);
  font-style: italic;
}

a:visited {
  color: var(--link);
}

.link-styled-button {
  background-color: transparent;
  border: none;
  color: var(--link);
  cursor: pointer;
  display: inline; 
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 1.0em;
  margin: 0;
  padding: 0;
  text-align: left;
}

button {
  background-color:var(--main-background);
  border: 2px solid var(--highlight-line);
  border-radius: 5px;
  color: var(--main-font);
  cursor: pointer;
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 16px;
  padding: 0.5rem 1rem;
}

input[type=radio] {
  vertical-align: baseline;
  margin-right: 1em;
  max-width: 20px;
}

#settingsContainer div {
  display: block
}

.settingsContainer .radio {
  margin-left: 1em;
}

#movementNotificationForm input[type=checkbox] {
  margin: 1em;
  max-width: 20px;
}

#movementNotificationForm input[type=number] {
  margin: 1em;
  max-width: 40px;
}

td {
  padding-right: 1em;
}

#code-of-conduct li {
  margin-bottom: 1.5em;
  padding-left: 0.5em;
}

#code-of-conduct ul {
  list-style-type: circle;
  padding-left: 2em;
}

.form-header {
  font-size: 1.3em;
  display: block;
  margin-bottom: 0.5em;
}

.settingsContainer .form {
  margin-bottom: 1em;
}

.spin {
  animation: rotate linear 500ms 2;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(359deg)
  }
}