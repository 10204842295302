/* TODO: refactor, this is all/only pertinent to the MessageView component now */

.message-wrapper .time {
  margin-right: 1.5em;
  color: var(--gray-text);
  float: left;
  font-size: 0.8em;
  text-transform: lowercase;
}

.message-wrapper {
  padding: 0.25rem;
}

.message-wrapper .time.show-on-hover {
  opacity: 0;
}

.message-wrapper:hover .time.show-on-hover {
  opacity: 1;
}

.message-wrapper.even-message {
  background-color: var(--alternate-background);
}

.message {
  overflow-wrap: anywhere;
}

.movement-message {
  opacity: 0.5;
}

.inline-custom-emoji {
  height: 1.3rem;
  position: relative;
  bottom: -0.2rem;
}
