#badges .badge {
  font-size: 25px;
}

#badges .selected-badge {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid var(--main-font);
  border-radius: 2px;
  display: inline-block;
  font-size: 25px;
  line-height: 32px;
  min-height: 32px;
  padding: 0 3px;
  margin: 5px;
  min-width: 1em;
  text-align: center;
  vertical-align: middle;
}

#badges .unlocked-badge, #badges .locked-badge {
  height: 28px;
  padding: 4px;
  padding-top: 8px;
  width: 30px;
}

#badges .locked-badge {
  opacity: 0.5;
  cursor: default;
}

#badges .unlocked-badge.selected {
  width: 26px;
}

#badges .selected {
  background: var(--alternate-background);
  border: 2px solid var(--active);
  border-radius: 2px;
}


#badges .badge-sections {
  display: inline-flex;
  width: 100%;
}

#badges .badge-sections .equipped {
  flex-shrink: 1;
  padding-right: 4rem;
  width: 33%;
}

#badges .toggle-badge-category {
  border: 0;
  color: var(--link);
  cursor: pointer;
}
