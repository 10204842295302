#side-menu {
  list-style: none;
  margin-top: 30px;
  position: fixed;
  width: 250px;
  z-index: 1;
}

@media only screen and (max-device-width: 500px) {
  #side-menu {
    background-color:var(--main-background);
    font-size: 30px;
    position: fixed;
    left: 0;
    height: 100vh;
    top: 0;
    width: 100vw;
    overflow: scroll;
    margin-top: 0;
    z-index: 10;
  }

  #side-menu #close-button {
    font-size: 64px;
    margin-right: 20px;
  }
}

#side-menu li {
  cursor: pointer;
  list-style: none;
}

button.nav-item {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--main-font);
  cursor: pointer;
  display: inline; 
  font-family: "IBM Plex Mono", "Consolas", "Courier New", Courier, monospace;
  font-size: 1.0em;
  margin: 0;
  padding: 0;
  text-align: left;
}

button.nav-item:hover, button.nav-item:focus, button.nav-item:active {
  text-decoration: none;
}

#menu-button {
  cursor: pointer;
  -webkit-user-select: none;
}

#side-menu ul {
  padding: 15px;
}

#nav-map-button {
  margin-left: 15px;
  margin-top: 2em;
}

#mini-map {
  border: 1px solid var(--main-font);
  overflow: hidden;
  width: 100%;
  height: 300px;
}

#mini-map .map, #mini-map .map pre {
  width: 100%;
  height: 100%;
}

#mini-map .map {
  font-size: 10px;
}
