/* background-color different than 'main' background, dropdown by username
'default' #333333
'light' base2 #eee8d5
'dark' base02 #073642 

'border' matches text
'default' white
'light'/'dark' #657b83

'selected' highlights item
'default' #aaaaaa
'light'/'dark' flip #*/

nav.react-contextmenu {
  background-color: var(--alternate-background);
  border: 1px solid var(--main-font);
  cursor: pointer;
  z-index: 1;
}

@media only screen and (max-device-width: 500px) {
  nav.react-contextmenu {
    font-size: 40px;
  }
}

.react-contextmenu-item {
  padding: 2px 5px;
}

.react-contextmenu-item--selected {
  background-color: var(--highlight-line) !important;
  color: var(--highlighted-line-font, --main-font);
}

span.name {
  cursor: pointer;
}

.name .react-contextmenu {
  color: var(--main-font);
  font-style: normal;
}

.name.Blue {
  color: var(--blue)
}
.name.Cyan {
  color: var(--cyan)
}
.name.Green {
  color: var(--green)
}
.name.Magenta {
  color: var(--magenta)
}
.name.Orange {
  color: var(--orange)
}
.name.Red {
  color: var(--red)
}
.name.Violet {
  color: var(--violet)
}
.name.Yellow {
  color: var(--yellow)
}

.name.font-Comic {
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
}
.name.font-Classic {
  font-family: 'TerminalFont';
}
.name.font-Impactful {
  font-family: 'Oswald';
}

.name .badge {
  margin-left: 3px;
  margin-right: 1px;
}

.custom-badge {
  margin-bottom: -4px;
  max-height: 20px;
}